@media all and (min-width: 480px) {
    .btn-primary {
        border-color: transparent;
    }

    #menubar-scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    #menubar-scroll::-webkit-scrollbar {
        width: 6px !important;
        background-color: #F5F5F5;
    }

    #menubar-scroll::-webkit-scrollbar-thumb {
        background-color: #939393 !important;
    }

    .menu-table button {
        height: 96px;
        width: 100%;
        border-radius: 0 !important;
        text-decoration:none;
    }

    .menu-table .toolStripButtonSelected {
        text-decoration: none;
    }

    .menu-table .btn-primary:focus {
        box-shadow: none;
        text-decoration: none;
    }
    .footer {
        font-size: 14px;
        text-align: center;
        color: white;
        height: 10vh;
        padding-top: 8px;
    }

    .react-pdf__Page__canvas{
        width: 100% !important;
    }

    .header {
        background-clip:border-box;
        border-bottom: 1px solid #e1e1e1;
    }

    .header shadow {
        /**box-shadow: 0 0.15rem 1.75rem 0 rgba(40, 41, 55, 0.15) !important;**/
    }

        .header span {
            /**color: #fff;**/
    }

    .header .form-inline .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
        display: inline-block;
        width: auto;
        vertical-align: middle;
        background-color: #f8f9fc !important;
        border: 0 !important;
    }

    .btn-primary, .btn, .btn-primary:hover, .btn:hover, .btn-primary:focus, .btn:focus {
        outline: none !important;
        outline-offset: unset !important;
        border-color: transparent !important;
        box-shadow: none !important;
        text-decoration: none;
    }

    .header .btn-primary {
        display: block;
        color: #fff;
        border-radius: 0px;
        /*box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.2);*/
        /*background-color: #114084;*/
        border-top: 1px solid rgba(255,255,255,0.5) !important;
        margin-top: -5px;
        text-decoration: none;
    }

    .header .btn {
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 2;
        width: 100%;
        text-align:left;
        text-decoration:none;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .border {
        border: 0px solid #dee2e6 !important;
    }

    .header .dropdown-toggle::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: none !important;
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }

    .search .card-body {
        height: 60vh !important;
        padding: 0.6rem;
    }
}

@media all and (min-width: 480px) {
    .view {
    }

    .message, .errorMessage {
        margin-top: 4px;
        margin-bottom: 4px;
        font-size: 20px;
    }

    .errorMessage {
        color: red;
    }

    .successMessage {
        color: green;
    }

    .legend, .globalLegend {
        margin: auto 0;
        color: #114084;
        /*font-size: 24px;*/
    }
    
    .legend {
        font-size: 20px;
    }

    .breadcrumbs {
        margin: auto 0;
        color: #114084;
        /*font-size: 24px;*/
    }

    .breadcrumbs {
        font-size: 20px;
    }

    .mainContainer {
        min-height: 100vh; /* will cover the 100% of viewport */
        width: 100%;
        overflow-y: hidden;
        display: block;
        position: relative;
       
    }
    
    .main-container {
        min-height: 100vh; /* will cover the 100% of viewport */
        width: 100%;
        overflow-y: hidden;
        display: block;
        position: relative;
       
    }

    .main-scroll {
        height: 100%;
    }

    #roo::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    #roo::-webkit-scrollbar {
        width: 0px;
        background-color: #F5F5F5;
    }

    #roo::-webkit-scrollbar-thumb {
        background-color: #787878;
    }
}

.btn:hover {
    opacity: .9;
}

.btn.disabled, .btn:disabled, .btn.disabled:hover {
    opacity: 0.3 !important;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
	outline: none !important;
}

.message, .errorMessage {
    font-size: 12px !important;
    font-weight: 400;
    padding-left: 16px;
}

.errorMessage {
    color: red;
}

.dash-logo{
height:96px;
width:96px;
}
.cust-menu{
width:20%;
}

    /* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .img-responsive {
        width: 50%;
        margin-top: 50px;
    }

    .card-container.sec-card {
        margin: 0px 0px 0px;
    }

    .img-responsive {
        margin-top: 50px;
    }

    .dash-logo {
        height: 20px;
        width: 20px;
    }

    .flex-column {
        width: 6.5rem !important;
    }
    .col-sm-6{
      z-index: -1;
    }
    .view {
        z-index: -1;
    }
    .cust-menu {
        width: 10%;
    }
    .menu-table {
       display:none;
    }
        .legend, .globalLegend {
            text-align: center;
        }

        .breadcrumbs {
            text-align: center;
        }
    }

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .img-responsive {
        margin-top: 50px;
    }
   
    .flex-column {
        width: 14rem !important;
    }
}
@media all and (min-width: 300px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .filterRow {
        overflow-y: hidden;
    }

    .filterContainer {
        width: 300px;
    }
}

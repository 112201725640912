/*
 * Specific styles of signin component
 */
/*
 * General styles
 */
#piecontainer {
    max-width: 800px;
    min-width: 320px;
    margin: 0 auto;
}

.highcharts-pie-series .highcharts-point {
    stroke: #EDE;
    stroke-width: 2px;
}

.highcharts-pie-series .highcharts-data-label-connector {
    stroke: silver;
    stroke-dasharray: 2, 2;
    stroke-width: 2px;
}
.role-container {
    position: relative;
    background-repeat: no-repeat;
    background-image: url(../../content/images/BankSeta_loginscreen.jpg);
    background-position: center;
    background-size: cover;
    height: 100%;
}

@media all and (min-width: 480px) {

   
    .RoleSelect {
        //padding: 60px 0;
        color:#fff;  
       
    }

    .logo-bottom-left {
        position: absolute;
        bottom: 20px;
        left: 20px;
        max-width: none;
        width: auto;
        height: 200px;
    }

    .role-btn{
        background: #28A745 !important;
        color:#fff;
    }
        .RoleSelect form,
        .RoleSelect table,
        .RoleSelect p {
            margin: 0 auto;
            max-width: 640px;
            max-height: 400px;
            overflow: auto;
        }

    .RoleSelect p {
        margin-bottom: 32px;
        font-size: 30px;
        color: #000;
    }

    .RoleSelect span {
        color: #000;
    }

        .RoleSelect table {
            margin-top: 20px;
        }

    .RoleSelect label {
        position: relative;
        cursor: pointer;
        color: #fff;
        font-size: 20px;
    }

    .RoleSelect input[type="checkbox"], input[type="radio"] {
        position: absolute;
        right: 9000px;
    }

        /*Check box*/
        .RoleSelect input[type="checkbox"] + .label-text:before {
            content: "\f0c8";
            font-family: "Font Awesome 5 Free";
            speak: none;
            font-style: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            width: 1em;
            display: inline-block;
            margin-right: 5px;
        }

    .RoleSelect input[type="checkbox"]:checked + .label-text:before {
        content: "\f14a";
        color: #2980b9;
        animation: effect 250ms ease-in;
        font-weight: 900;
    }

    .RoleSelect input[type="checkbox"]:disabled + .label-text {
        color: #aaa;
    }

    .RoleSelect input[type="checkbox"]:disabled + .label-text:before {
        content: "\f0c8";
        color: #ccc;
    }

        /*Radio box*/

     .RoleSelect  input[type="radio"] + .label-text:before {
            content: "\f111";
            font-family: "Font Awesome 5 Free";
            speak: none;
            font-style: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            width: 1em;
            display: inline-block;
            margin-right: 5px;
        }

    .RoleSelect input[type="radio"]:checked + .label-text:before {
        content: "\f192";
        color: #000;
        animation: effect 250ms ease-in;
    }

        .RoleSelect input[type="radio"]:disabled + .label-text {
            color: #aaa;
        }

            input[type="radio"]:disabled + .label-text:before {
                content: "\f111";
                color: #ccc;
            }

    /*Radio Toggle*/

    .RoleSelect .toggle input[type="radio"] + .label-text:before {
        content: "\f204";
        font-family: "Font Awesome 5 Free";
        speak: none;
        font-style: normal;
        font-weight: 900;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        width: 1em;
        display: inline-block;
        margin-right: 10px;
    }

    .RoleSelect .toggle input[type="radio"]:checked + .label-text:before {
        content: "\f205";
        color: #16a085;
        animation: effect 250ms ease-in;
    }

    .RoleSelect .toggle input[type="radio"]:disabled + .label-text {
        color: #aaa;
    }

    .RoleSelect .toggle input[type="radio"]:disabled + .label-text:before {
            content: "\f204";
            color: #ccc;
    }

    /*Scroller */

    .scrollbar {
        margin-left: 30px;
        float: left;
        height: 300px;
        width: 65px;
        background: #F5F5F5;
        overflow-y: scroll;
        margin-bottom: 25px;
    }

    .force-overflow {
        min-height: 450px;
    }

    #scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    #scroll::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }

    #scroll::-webkit-scrollbar-thumb {
        background-color: #000000;
    }



    @keyframes effect {
        0% {
            transform: scale(0);
        }

        25% {
            transform: scale(1.3);
        }

        75% {
            transform: scale(1.4);
        }

        100% {
            transform: scale(1);
        }
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .RoleSelect {
       /* background-repeat: no-repeat;
        background-image: url('/images/roles_background.jpeg');
        background-size: 100% 100%;*/
        color:#fff;
        padding:60px; 
    } 

}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .RoleSelect {
        padding: 60px 0;
        color: #fff;
    }
        .RoleSelect form,
        .RoleSelect table,
        .RoleSelect p {
            margin: 0 auto;
            max-width: 640px;
            max-height: 800px;
            overflow: auto;
        }
}

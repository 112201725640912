/*
 * Specific styles of signin component
 */
/*
 * General styles
 */

@import 'https://code.highcharts.com/css/highcharts.css';


#linecontainer {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    color: white;
}

.card-header {
    background-color: darkgray;
}

.highcharts-background {
    fill: #28282B;
    color: white;
}

.highcharts-color-0 {
    fill: #0460ba;
    stroke: #0460ba;
}

.highcharts-color-1 {
    fill: #9696ab;
    stroke: #9696ab;
}


.highcharts-container text {
    fill: #c0c0c0;
}

.highcharts-subtitle,
.highcharts-credits,
.highcharts-axis-title {
    fill-opacity: 0.7;
}

.highcharts-grid-line {
    stroke: silver;
    stroke-opacity: 0.2;
}

.highcharts-tooltip-box {
    fill: var(--background-color);
}

.highcharts-column-series rect.highcharts-point {
    stroke: silver;
    color: #c0c0c0;
}

/*input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}*/

input[type=number]{
  font-size:12px;
  padding: 5px;
  height: 30px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.space{
  height: 20px;
}

.FormsTableHeader {
    border: 1px solid #C0C0C0;
    border-collapse: collapse;
    padding: 2px;
    margin: 0px;
    width: 100%;
}
@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
        height: auto !important;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }
}



@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .providerForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .providerFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .searchResults {
        margin-top: 8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }
}

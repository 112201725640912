.btn-margin{
    margin-left: 38px;
    margin-bottom: -15px;
}

@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .SDFForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .SDFFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
    .title{
        margin-top: 10px;
        text-align: center;
    }
  
}

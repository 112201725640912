@media all and (min-width: 480px) {
    .searchResults {
        margin-top: 8px;
        width: 100%;
        height: calc(100% - 200px);
    }

    .addLearnerSearchResults {
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 32px;
        width: 100%;
        height: calc(100% - 50px);
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        overflow: auto;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .unitStandardFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {
    .searchResults {
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }

    .tabPanel {
        width: 100%;
        height: 20vh;
        overflow-y: auto;
    }

        .tabPanel div:first-child {
            height: auto !important;
        }

    .wideDrop {
        width: 300px;
    }
}

@media all and (min-width: 480px) {
    .MuiOutlinedInput-root, .MuiFormLabel-root {
        margin-top: 8px;
    }

    .MuiFormControl-root {
        margin: 0 !important;
    }

    .custom-scrollbar {
        position: relative;
        height: 200px;
        overflow: auto;
    }

    .formContainer {
        padding-top: 4px;
    }

    .form-col {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .search-form-container {
        width: 98%;
        overflow: hidden;
        padding-left: 16px;
        padding-right: 16px;
    }
}
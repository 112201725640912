@media all and (min-width: 480px) {
    .searchResults {
        margin-top:8px;
        width: 100%;
    }

    .tabContainer {
        margin-top: 32px;
    }
    .my-custom-scrollbar {
        position: relative;
        height: 200px;
        overflow: auto;
    }
}
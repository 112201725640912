.legend{
    font-size: 12,
}
.breadcrumbs {
    font-size: 12,
}

.formContainer{
    width: 100%;
}

@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .organisationForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .organisationFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}

@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .SDFForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }

    .SDFFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}
